import config from '../config/config'

export const buyPackage = (selectedPackage: any, auth: any, lang: string, metadata: any) => {
    const name = metadata.name || auth?.displayName || ''
    const email = metadata.email || auth?.email || ''
    const phone = metadata.phone || auth?.phoneNumber || ''
    const [firstName, lastName] = (name || '').split(' ', 2)
    const sandbox =  true

    const queryParams = [
        'id=' + auth.uid,
        'amount=' + selectedPackage.price,
        'firstName=' + firstName,
        'lastName=' + lastName,
        'email=' + email,
        'mobilePhone=' + phone,
        'cause=RO',
        'lang=' + lang,
        'sandbox=' + sandbox,
    ]

    const queryString = '?' + queryParams.join('&')
    window.location.href = config.functionsEndpoint + 'pay' + queryString
}
