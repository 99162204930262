import React from 'react'
import './card.scss'

interface IProps {
    children: React.ReactNode[] | React.ReactNode
    className?: string
}

export const Card = (props: IProps) => {
    return <div className={`card-container ${props.className ?? ''}`}>{props.children}</div>
}
