import React, { useEffect, useState } from 'react'
import { Language, translate } from '../../i18n'

//@ts-ignore
import Select from 'react-select-native'
import './package-info.scss'
import { Input } from '../input/input'
import { Checkbox } from '../checkbox/checkbox'
import { counties } from '../../config/constants'
import Modal from 'react-modal'
import { Card } from '../card/card'

const regTextDot = /^[A-Za-z0-9_ \.:\-]*$/
const regTextZip = /^[0-9]*$/
const regTextPhone = /^[\+ \(\)0-9\-]*$/

export interface IProps {
    onBuyAction: ((event: any) => void) | undefined
    price?: number
    type?: string
    currency?: string
    delivery?: boolean
    unavailable?: boolean
    metadata?: any
    activatingWithCode: boolean
}

const useFormField = (initialValue: string, validator: (value: string) => boolean) => {
    const [value, setValue] = useState(initialValue)
    const [isValid, setIsValid] = useState(false)
    const [validated, setValidated] = useState(false)

    useEffect(() => {
        setIsValid(validator(value))
    }, [value])

    return {
        value,
        setValue,
        isValid,
        validated,
        setValidated,
    }
}

const validateName = (name: string) => (name || '').length >= 5 && regTextDot.test(name)
const validateAddress = (address: string) => (address || '').length >= 5
const validateCity = (city: string) => (city || '').length >= 3 && regTextDot.test(city)
const validateCounty = (county: string) => (county || '').length >= 3
const validateZip = (zip: string) => (zip || '').length >= 4 && regTextZip.test(zip)
const validatePhone = (phone: string) => (phone || '').length >= 7 && regTextPhone.test(phone)

const InputField = ({ field, label, onTap, readonly }: any) => (
    <Input
        validated={field.validated}
        isValid={field.isValid}
        value={field.value}
        label={translate(label)}
        onChangeText={(text: string) => field.setValue(text)}
        onBlur={() => field.setValidated(true)}
        onTap={onTap}
        readonly={readonly}
    />
)

export const PackageInfo = (props: IProps) => {
    const [deliverySelected, setDeliverySelected] = useState(2)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [allInputsAreValid, setAllInputsAreValid] = useState(false)
    const nameField = useFormField(props?.metadata?.name, validateName)
    const addressField = useFormField('', validateAddress)
    const cityField = useFormField('', validateCity)
    const countyField = useFormField('', validateCounty)
    const zipField = useFormField('', validateZip)
    const phoneField = useFormField(props?.metadata?.phone, validatePhone)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const validateForm = () => {
        if (deliverySelected !== 1 && deliverySelected !== 2) return false

        if (deliverySelected === 2) {
            nameField.setValidated(true)
            addressField.setValidated(true)
            cityField.setValidated(true)
            countyField.setValidated(true)
            zipField.setValidated(true)
            phoneField.setValidated(true)
            return (
                nameField.isValid &&
                addressField.isValid &&
                cityField.isValid &&
                countyField.isValid &&
                zipField.isValid &&
                phoneField.isValid
            )
        }
        return true
    }

    useEffect(() => {
        //validate props that are prepopulated
        if (props.delivery) {
            nameField.value && nameField.setValidated(true)
            addressField.value && addressField.setValidated(true)
            cityField.value && cityField.setValidated(true)
            countyField.value && countyField.setValidated(true)
            zipField.value && zipField.setValidated(true)
            phoneField.value && phoneField.setValidated(true)
        }
    }, [])

    useEffect(() => {
        setAllInputsAreValid(
            nameField.isValid &&
                addressField.isValid &&
                cityField.isValid &&
                countyField.isValid &&
                zipField.isValid &&
                phoneField.isValid
        )
    }, [
        nameField.isValid,
        addressField.isValid,
        cityField.isValid,
        countyField.isValid,
        zipField.isValid,
        phoneField.isValid,
    ])

    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0
            })
        }, 50)
    }, [])

    return (
        <Card className="package-info">
            <div className={`wm-title`}>{translate(`payments.activeYour`)}</div>
            <div className={`package-name package-type ${props.type}`}>
                {translate(`packages.packageDisplayName_${props.type}`)}{' '}
            </div>

            {!props.delivery && (
                <div className="instructions">{translate('payments.instructions')}</div>
            )}

            {props.delivery && (
                <>
                    <div className="instructions">{translate('payments.pickup')}</div>
                    <div className="pickupSelection">
                        <Checkbox
                            label={translate('pickup.theOffice')}
                            checked={deliverySelected === 1}
                            onTap={() => setDeliverySelected(1)}
                        />
                        <Checkbox
                            label={translate('pickup.delivery')}
                            checked={deliverySelected === 2}
                            onTap={() => setDeliverySelected(2)}
                        />
                        {deliverySelected === 2 && (
                            <div className="address">
                                <InputField field={nameField} label="common.name" />
                                <InputField field={addressField} label="common.address" />
                                <InputField field={cityField} label="common.city" />
                                <InputField
                                    field={countyField}
                                    label="common.county"
                                    onTap={openModal}
                                    readonly
                                />
                                <InputField field={zipField} label="common.zip" />
                                <InputField field={phoneField} label="common.phone" />
                            </div>
                        )}
                    </div>
                </>
            )}

            <div className="button-container">
                <button
                    className={`buy-button ${
                        !allInputsAreValid && props.delivery && deliverySelected === 2 && 'disabled'
                    }`}
                    onClick={() => {
                        if (props.delivery && !validateForm()) {
                            alert('Please provide delivery information')
                        } else {
                            props.onBuyAction &&
                                props.onBuyAction({
                                    delivery: props.delivery,
                                    deliveryMethod: deliverySelected === 1 ? 'pickup' : 'courier',
                                    pickupLocation:
                                        deliverySelected === 1
                                            ? translate('pickup.theOffice')
                                            : translate('pickup.delivery'),
                                    name: nameField.value,
                                    address: addressField.value,
                                    city: cityField.value,
                                    county: countyField.value,
                                    zip: zipField.value,
                                    phone: phoneField.value,
                                })
                        }
                    }}
                >
                    {translate('common.continue')}
                </button>
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: '#0000003b',
                        backdropFilter: 'blur(2px)',
                        zIndex: 5,
                    },
                    content: {
                        overflow: 'auto',
                        borderRadius: '20px',
                        backgroundColor: '#383838',
                        border: 'none',
                        inset: '10vh 40px',
                    },
                }}
                contentLabel="Select county"
            >
                <div className="modal-title">{translate('common.selectCounty')}</div>
                <div className="modal-layout">
                    <div className="modal-content">
                        {counties.map((c, i) => (
                            <div
                                className={`county ${c === countyField.value && 'selected'}`}
                                key={i}
                                onClick={() => {
                                    countyField.setValue(c)
                                    closeModal()
                                }}
                            >
                                {c}
                            </div>
                        ))}
                    </div>
                    <div className="modal-button">
                        <button onClick={closeModal}>{translate('common.back')}</button>
                    </div>
                </div>
            </Modal>
        </Card>
    )
}
