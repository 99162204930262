import { translate } from '../i18n'

export interface IPaymentResult {
    success: boolean
    title: string
    description: string
    package: string
}

const packages: any = {
    499: 'vip',
    250: 'premium',
    99: 'basic',
}

export const getPaymentResult = (paymentData: any): IPaymentResult => {
    let success = false
    let title = ''
    let description = ''

    const selectedPackage = packages[parseInt(paymentData.processedAmount)]

    if (paymentData.responseErrorCode !== '0') {
        success = false
        title = translate('payments.rejected')
        description = translate('payments.rejectedDesc')
    } else {
        switch (paymentData.responseAction) {
            case 'confirmed':
                success = true
                title = translate('payments.confirmed')
                description = translate('payments.confirmedDesc')
                break
            case 'confirmed_pending':
                success = true
                title = translate('payments.pending')
                description = translate('payments.pendingDesc')
                break
            case 'paid_pending':
                success = true
                title = translate('payments.pending')
                description = translate('payments.pendingDesc')
                break
            case 'paid':
                success = true
                title = translate('payments.pending')
                description = translate('payments.pendingDesc')
                break
            case 'canceled':
                success = false
                title = translate('payments.canceled')
                description = translate('payments.canceledDesc')
                break
            case 'credit':
                success = false
                title = translate('payments.refund')
                description = translate('payments.refundDesc')
                break
            default:
                success = false
                title = translate('payments.notCompleted')
                description = translate('payments.notCompletedDesc')
                break
        }
    }

    return {
        success,
        title,
        description,
        package: selectedPackage
    }
}
