import React from 'react'
import './app.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Navigation } from './components/navigation/navigation'
import * as ROUTES from './constants/routes'
import { Home } from './pages/home/home'

import { Language, loadTranslations, translate } from './i18n'
import { SplashScreen } from './components/splash-screen/splash-screen'
import { connect } from 'react-redux'
import { RootState } from './redux/store'
import { firebaseConnect, firestoreConnect, useFirebase, withFirebase } from 'react-redux-firebase'
import { LoggedOut } from './components/logged-out/logged-out'
import { compose } from 'redux'
import config from './config/config'
import { Return } from './pages/return/return'
import { selectLanguage } from './redux/app/actions'
import { COMPETITION } from './config/constants'

interface IState {
    tokenPresent: boolean
}

interface IProps {
    auth: any
    packages: any
    metadata: any
    enablePayments: boolean
    language: Language
    currency: string
    selectLanguage: any
    competition: string
    confirmedPayment: boolean
}

const isAppReady = (props: IProps, state: IState): boolean => {
    return props.auth?.isLoaded && props.packages && !state.tokenPresent
}

class App extends React.Component<IProps, IState> {
    languageDetected = false

    constructor(props: any) {
        super(props)
        loadTranslations(this.props.language)
        const params = new URLSearchParams(window.location?.search)
        const token = params.get('t')
        if (token) {
            props.firebase
                .login({
                    token: token,
                    profile: {},
                })
                .then(() => {
                    window.location.replace('/')
                })
        }

        this.state = {
            tokenPresent: !!token,
        }
    }

    detectLanguage(metadata: any) {
        const params = new URLSearchParams(window.location?.search)
        const lang = params.get('lang')
        return lang || metadata?.language || (navigator.language === 'ro-RO' && 'ro') || 'en'
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.metadata) {
            // initial language detection from metadata / browser
            if (
                !this.languageDetected ||
                this.props.metadata.language !== prevProps?.metatada?.language
            ) {
                const language = this.detectLanguage(this.props.metadata)

                this.languageDetected = true

                if (language !== this.props.language) {
                    this.props.selectLanguage(language)
                }
            }

            if (prevProps.language !== this.props.language) {
                loadTranslations(this.props.language)
            }
        }
    }

    showPeriodOverScreen() {
        return (
            <div className="periodOver">
                <div>{translate('payments.periodOver')}</div>
            </div>
        )
    }

    shouldDisplaySplashScreen() {
        if (!isAppReady(this.props, this.state)) {
            return true
        }

        if (this.props.auth?.isLoaded && this.props.auth.isEmpty) {
            return true
        }

        return false
    }

    render() {
        if(this.shouldDisplaySplashScreen()) {
            return <SplashScreen />
        }

        if (!this.props.enablePayments && !this.props?.metadata?.tester) {
            return this.showPeriodOverScreen()
        }

        if(this.props.auth?.isLoaded && this.props.auth.isEmpty) {
            return <LoggedOut />
        }

        return (
            <div className="app">
                <Router>
                    <Route
                        exact
                        path={ROUTES.HOME}
                        component={() => (
                            <Home
                                packages={this.props.packages}
                                currency={this.props.currency}
                                confirmedPayment={this.props.confirmedPayment}
                                metadata={this.props.metadata}
                            />
                        )}
                    />
                    <Route path={ROUTES.RETURN} component={Return} />
                </Router>
            </div>
        )
    }
}

const enhance = compose(
    connect(({ firebase: { auth } }: RootState) => ({
        auth,
    })),
    compose(
        withFirebase,

        firestoreConnect((props: any) => {
            return [
                { collection: 'competitions', doc: COMPETITION },
                { collection: 'users', doc: (props as any).auth.uid },
                {
                    collection: `competitions/${COMPETITION}/user-payments`,
                    doc: (props as any).auth.uid,
                },
                `/${config.CURRENT_YEAR}/config/enablePayments`,
                // `/usersMetadata/${(props as any).auth.uid}`,
            ]
        }),

        connect(
            ({ firestore: { data }, app }: RootState, props) => {
                return {
                    packages: data?.competitions?.[COMPETITION]?.packages,
                    language: app.language,
                    metadata: data?.users?.[(props as any).auth.uid],
                    enablePayments:
                        data?.competitions?.[COMPETITION]?.configs?.flags?.['enable-payments'],
                    confirmedPayment:
                        data?.[`competitions/${COMPETITION}/user-payments`]?.[
                            (props as any).auth.uid
                        ]?.status === 'confirmed',
                    currency: data?.competitions?.[COMPETITION]?.currency,
                }
            },
            (dispatch) => ({
                selectLanguage: (language: Language) => dispatch(selectLanguage(language)),
            })
        )
    )
)

//@ts-ignore
export default enhance(App) as any
