import React from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import './logged-out.scss'

export const LoggedOut = (props: any) => {
    const firebase = useFirebase()
    const params = new URLSearchParams(window.location?.search)
    const token = params.get('t')

    if (token) {
        firebase.login({
            token: token,
            profile: {},
        }).then(() => {
            window.location.replace('/')
        })
    }
    
    return (
        <div className="logged-out">
            <div className="download-app">
                Please download the Walking Month app on Google Play Store or App Store.
            </div>
        </div>
    )
}
