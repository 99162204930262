import React from 'react'
import './checkbox.scss'

export const Checkbox = ({ label, checked, onTap }: any) => {
    return (
        <div
            className="checkbox-component"
            onClick={() => {
                onTap()
            }}
        >
            <div className="check-icon">
                {checked && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="Icon / Interface / Check">
                            <path
                                id="Vector"
                                d="M6 12L10.2426 16.2426L18.727 7.75732"
                                stroke="#7AC5A1"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </svg>
                )}
            </div>
            <div className="label">{label}</div>
        </div>
    )
}
