/* eslint-disable quotes */
import { ITranslations } from '../types'

export const en: ITranslations = {
    texts: {
        common: {
            CHOOSE: 'CHOOSE',
            BUY: 'Choose the {{package}}',
            PACKAGE: 'PACKAGE',
            back: 'Back',
            selectCounty: 'Select county',
            name: 'Name',
            address: 'Address',
            city: 'City',
            county: 'County',
            zip: 'Zip code',
            phone: 'Phone',
            continue: 'Continue'
        },
        prices: {
            for: 'for'
        },
        navigation: {
            dashboard: 'Dashboard',
            activationOptions: 'Activation options',
            backToApp: 'Back to app',
        },
        packages: {
            donationInfo: '100% of registration fees will be donated directly to the partner NGO',
            selected: 'You selected',
            continueWith: "Continue with",
            selectionText:
                'All proceeds will go towards helping neurodivergent children',
            packageDisplayName_basic: 'Basic Package',
            packageDisplayName_premium: 'Premium Package',
            packageDisplayName_vip: 'VIP Package',
            onlyRomaniaTitle: 'Only available in Romania',
            onlyRomania: " Premium and VIP packages can only be delivered in Romania. If you live in another country, please choose the Basic donation package. 100% of the registration fees are directed to the local NGO.",
            packageDescription_basic:
                'Choose the Basic package to use with your existing fitness tracker.',
            packageDescription_premium:
                'Choose the Premium package to receive a brand new Mi Band 8 to help you track every awesome step..',
            packageDescription_vip: `Choose our VIP, super duper, cool-as-can-be package. And it gets you our extra special, surprise-laden, Walking Month goodie bag.`,
        },
        payments: {
            activateYourAccount: 'Activate your account',
            alreadyActivated: 'Your account was already activated.',
            cause: 'Choose one of our three packages and help us donate 1.000.000 minutes of movement for kids.',
            canceled: 'Payment canceled',
            canceledDesc: 'Your payment has been canceled.',
            confirmed: 'Payment successful',
            confirmedDesc: 'Your payment has been confirmed.',
            codeTitle: 'Got a company code? Enter it below.',
            haveCode: 'Have an activation code?',
            companyCode: 'Company code',
            instructions:
                'Use the button below to begin payment using our trusted payment provider.',
            instructionsWithPickup:
                'Select your merch pickup location, then use the button below to begin payment using our trusted payment provider.',
            notCompleted: 'Payment not completed',
            notCompletedDesc: 'Payment was not completed. Please start the payment proocess again.',
            pending: 'Payment pending',
            pendingDesc: 'Your payment is pending, but it will be confirmed as soon as possible.',
            periodOver: 'It is not possible to activate your account at this time.',
            pickup: `Tell us where we should send your tracker to, then use the button to begin payment using our trusted payment provider.`,
            refund: 'Payment refunded',
            refundDesc: 'The payment has been refunded. You will get your money back soon.',
            rejected: 'Payment rejected',
            rejectedDesc:
                'There was a problem processing your payment. Please start the payment proocess again.',
            useCode: 'Use code',
            submit: 'Submit',
            activeYour: 'Activate your',
        },
        return: {
            activationComplete: 'Activation complete',
            setToWalk: 'You are all set to start walking!',
            success: 'You did it!',
            error: 'Oops, error :(',
            transactionId: 'Your order ID is:',
            emailInfo: 'These information will also be sent to you over e-mail',
            finlize: 'for',
            tryAgain: 'Try again',
            loading: 'loading order info',
            orderFinishedDesc: `You can now join a team or even start your own team! And if you’re the lone wolf type, don’t worry, you can still take part in Walking Month even without a team.`,
            finishActivation: 'Finish activation',
        },
        pickup: {
            delivery: 'Express courier delivery in Romania',
            theOffice: 'Pickup in Cluj-Napoca, at The Office, (December 21st 1989 Blvd, no 77), entrance A, Floor 4, Mondays through Fridays, 9 AM - 6 PM. Please have a photo ID ready - national ID card for Romanian citizens.'
        }
    },
    plural: (n: number, ord?: boolean) => {
        const s = String(n).split('.')
        const v0 = !s[1]
        const t0 = Number(s[0]) === n
        const n10 = t0 && s[0].slice(-1)
        const n100 = t0 && s[0].slice(-2)

        if (ord) {
            return n10 === '1' && n100 !== '11'
                ? 'one'
                : n10 === '2' && n100 !== '12'
                ? 'two'
                : n10 === '3' && n100 !== '13'
                ? 'few'
                : 'other'
        }
        return n === 1 && v0 ? 'one' : 'other'
    },
}
