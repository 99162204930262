const dev = {
    // functionsEndpoint: 'http://localhost:5001/walkinthepark-3c783/us-central1/',
    functionsEndpoint: 'https://us-central1-walkinthepark-3c783.cloudfunctions.net/',
}

const prod = {
    functionsEndpoint: 'https://us-central1-walkinthepark-3c783.cloudfunctions.net/',
}

const config = process.env.NODE_ENV === 'production' ? prod : dev

export default {
    CURRENT_YEAR: '2024',
    firebase: {
        apiKey: 'AIzaSyANOpsxfWjxU6czINz4QJLJtbPL4rERaQg',
        authDomain: 'walkinthepark-3c783.firebaseapp.com',
        databaseURL: 'https://walkinthepark-3c783.firebaseio.com',
        projectId: 'walkinthepark-3c783',
        storageBucket: 'walkinthepark-3c783.appspot.com',
        messagingSenderId: '732126244055',
        appId: '1:732126244055:web:80d67d59d772ba2cc59492',
    },
    ...config,
}
