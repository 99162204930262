import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { MdChevronLeft } from 'react-icons/md'
import { ProgressCircle } from '../../components/progress-circle/progress-circle'
import { Package } from '../../components/package/package'
import { PackageInfo } from '../../components/package-info/package-info-v2'
import { buyPackage } from '../../core/buy-package'
import { User } from '../../components/user/user'
import { Language, translate } from '../../i18n'
import './home.scss'
import { PaymentDone } from '../payment-done/payment-done'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { selectCompetition } from '../../redux/app/reducer'
import { COMPETITION } from '../../config/constants'
import config from '../../config/config'
import { Card } from '../../components/card/card'
import { TicketIcon } from '../../components/icons/ticket'
import { returnToApp } from '../../core/return-to-app'
import Modal from 'react-modal'

const activateWithCode = (
    activationCode: string,
    uid: string,
    callback: (status: boolean) => void
) => {
    const url = `${config.functionsEndpoint}activate?code=${activationCode}&uid=${uid}`
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            if (!data.success) {
                callback(false)
                alert('Error\n' + data.error)
                return
            }

            callback(true)
        })
        .catch((error) => {
            console.log(error)
            callback(false)
        })
}

const validateCode = (
    activationCode: string,
    callback: (status: boolean, packageType?: string) => void
) => {
    const url = `${config.functionsEndpoint}validateCode?code=${activationCode}`
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            if (!data.success) {
                callback(false)
                alert('Error\n' + data.error)
                return
            }

            callback(true, data.package)
        })
        .catch((error) => {
            console.log('First', error)
            callback(false)
        })
}

interface TopBarProps {
    onClickBackDo: () => void
    titleKey?: string
}

const TopBar = (props: TopBarProps) => {
    const callback = typeof props.onClickBackDo === 'function' ? props.onClickBackDo : () => {}
    const title = props.titleKey ? translate(`navigation.${props.titleKey}`) : ''
    return (
        <div className="top-bar">
            <div className="back-arrow" onClick={() => callback()}>
                <MdChevronLeft color={'#D1D3D5'} />
            </div>
            <div className="title" onClick={() => callback()}>
                {title || ''}
            </div>
        </div>
    )
}

const HomePage = (props: any) => {
    const [selectedPackage, selectPackage] = useState({})
    const [packageWithConfirmation, setPackageWithConfirmation] = useState({})
    const [sortedPackages, setSortedPackages] = useState([])
    const [activationCode, setActivationCode] = useState('')
    const [loadingIndicator, setLoadingIndicator] = useState(false)
    const [activatingWithCode, setActivatingWithCode] = useState(false)
    const [topBarTitle, setTopBarTitle] = useState('')
    const firestore = useFirestore()

    const auth = useSelector((state: RootState) => state.firebase.auth)
    const competition = useSelector(selectCompetition)

    useEffect(() => {
        const sorted = []
            .concat(props.packages)
            .sort((a: any, b: any) => (a.price > b.price ? -1 : 1))
        setSortedPackages(sorted)
    }, [props.packages])

    useEffect(() => {
        if (Object.keys(selectedPackage).length !== 0) {
            setTopBarTitle('activationOptions')
        } else {
            setTopBarTitle('dashboard')
        }
    }, [selectedPackage])

    const language: Language = useSelector((state: RootState) => state.app.language)
    const shouldDisplayConfirmationModal =
        packageWithConfirmation && Object.keys(packageWithConfirmation).length !== 0
    //@ts-ignore
    const basicPackage = sortedPackages.find((p: unknown) => p.type === 'basic') as Record<
        string,
        unknown
    >

    const onClickBackDo = () => {
        // return to home when a package is selected
        if (Object.keys(selectedPackage).length !== 0) {
            selectPackage({})
            setActivatingWithCode(false)
            return
        }

        // return to app when the route is home (packages list
        returnToApp()
    }

    const onContinueWithPackageClickedDo = (p: Record<string, unknown>) => {
        selectPackage(packageWithConfirmation)
        setPackageWithConfirmation({})
    }

    const onContinueWithBasicPackageClickedDo = (p: Record<string, unknown>) => {
        setPackageWithConfirmation({})
        selectPackage(p)
    }

    return (
        <div className="home-page">
            <TopBar titleKey={topBarTitle} onClickBackDo={onClickBackDo} />
            {loadingIndicator && (
                <div className="loading-indicator">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {props.confirmedPayment && <PaymentDone />}
            {!props.confirmedPayment && (
                <>
                    {/* <ProgressCircle {...{ percent }} /> */}
                    <div className="packages-wrapper card">
                        {/* <div className="donation-info">{translate('packages.donationInfo')}</div> */}
                        {Object.keys(selectedPackage).length !== 0 ? (
                            <PackageInfo
                                {...selectedPackage}
                                currency={props.packages.currency}
                                metadata={props.metadata}
                                activatingWithCode={activatingWithCode}
                                onBuyAction={(data) => {
                                    if (!auth?.uid) {
                                        alert('Error fetching current use info')
                                        return
                                    }

                                    if (data.delivery) {
                                        const updateObject: any = {
                                            pickupLocation: data.pickupLocation,
                                            pickupMethod: data.deliveryMethod,
                                        }
                                        if (data.deliveryMethod === 'courier') {
                                            updateObject.deliveryAddress = {}
                                            updateObject.deliveryAddress['name'] = data.name
                                            updateObject.deliveryAddress['address'] = data.address
                                            updateObject.deliveryAddress['city'] = data.city
                                            updateObject.deliveryAddress['county'] = data.county
                                            updateObject.deliveryAddress['zip'] = data.zip
                                            updateObject.deliveryAddress['phone'] = data.phone
                                        }

                                        firestore
                                            .collection(`competitions/${COMPETITION}/users`)
                                            .doc(auth?.uid)
                                            .set(updateObject, { merge: true })
                                            .then(() => {
                                                if (activatingWithCode) {
                                                    setLoadingIndicator(true)
                                                    activateWithCode(
                                                        activationCode,
                                                        auth.uid,
                                                        () => {
                                                            setLoadingIndicator(false)
                                                        }
                                                    )
                                                } else {
                                                    buyPackage(
                                                        selectedPackage,
                                                        props.auth,
                                                        language,
                                                        props.metadata
                                                    )
                                                }
                                            })
                                            .catch((e) => {
                                                alert('Error setting pickup location')
                                            })
                                    } else {
                                        buyPackage(
                                            selectedPackage,
                                            props.auth,
                                            language,
                                            props.metadata
                                        )
                                    }
                                }}
                            />
                        ) : (
                            <>
                                <Modal
                                    ariaHideApp={false}
                                    isOpen={shouldDisplayConfirmationModal}
                                    onRequestClose={() => {
                                        console.log('closed')
                                    }}
                                    style={{
                                        overlay: {
                                            backgroundColor: '#0000003b',
                                            backdropFilter: 'blur(5px)',
                                            zIndex: 5,
                                        },
                                        content: {
                                            overflow: 'auto',
                                            borderRadius: '20px',
                                            backgroundColor: '#383838',
                                            border: 'none',
                                            inset: '10vh 40px',
                                        },
                                    }}
                                    contentLabel="Continue with Package"
                                >
                                    <div className="modal-content">
                                        <h3 className={'wm-title active-title'}>
                                            {translate('packages.onlyRomaniaTitle')}
                                        </h3>
                                        <p className={'modal-text'}>
                                            {translate('packages.onlyRomania')}
                                        </p>

                                        <div className="modal-buttons">
                                            <button onClick={() =>
                                                    onContinueWithPackageClickedDo(
                                                        packageWithConfirmation
                                                    )
                                                }
                                            >
                                                {translate('packages.continueWith')}
                                                {' '}
                                                {
                                                    // @ts-ignore
                                                    translate(`packages.packageDisplayName_${packageWithConfirmation.type}`
                                                )}
                                            </button>
                                            <button
                                                onClick={() =>
                                                    onContinueWithBasicPackageClickedDo(
                                                        basicPackage
                                                    )
                                                }
                                                className="secondary"
                                            >
                                                {translate('packages.continueWith')}
                                                {' '}
                                                {
                                                    // @ts-ignore
                                                    translate(`packages.packageDisplayName_basic`
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </Modal>

                                <Card className={'activate-container'}>
                                    <div className="wm-title active-title">
                                        {translate('payments.activateYourAccount')}
                                    </div>
                                    <div className="package-select-text">
                                        {translate('packages.selectionText')}
                                    </div>
                                </Card>
                                <div className="packages-container">
                                    {sortedPackages &&
                                        sortedPackages.map((p: any) => (
                                            <Card key={p.type}>
                                                <Package
                                                    {...p}
                                                    metadata={props.metadata}
                                                    currency={p.currency}
                                                    withTitle={p.type !== 'vip'}
                                                    withImage={['vip', 'premium'].includes(p.type)}
                                                    onClick={() => {
                                                        if (['vip', 'premium'].includes(p.type)) {
                                                            setPackageWithConfirmation(p)
                                                        } else {
                                                            selectPackage(p)
                                                        }
                                                    }}
                                                />
                                            </Card>
                                        ))}
                                </div>
                                <Card>
                                    <div className="activation-code">
                                        <div className="activation-code__title">
                                            {translate('payments.haveCode')}
                                        </div>
                                        <div
                                            className="input-box-v2"
                                            style={{ marginBottom: '24px' }}
                                        >
                                            <input
                                                type="text"
                                                value={activationCode}
                                                required
                                                onChange={(event) => {
                                                    setActivationCode(event.target.value)
                                                }}
                                            ></input>
                                            <label>{translate('payments.companyCode')}</label>
                                        </div>
                                        <div className="button-container button-container__submit">
                                            <button
                                                onClick={() => {
                                                    if (activationCode) {
                                                        setLoadingIndicator(true)
                                                        validateCode(
                                                            activationCode,
                                                            (success, packageType) => {
                                                                if (!success) {
                                                                    setLoadingIndicator(false)
                                                                    return
                                                                }

                                                                if (packageType === 'vip') {
                                                                    setLoadingIndicator(false)
                                                                    setActivatingWithCode(true)
                                                                    selectPackage(
                                                                        sortedPackages.find(
                                                                            (p: any) => {
                                                                                return (
                                                                                    p.type === 'vip'
                                                                                )
                                                                            }
                                                                        ) || {}
                                                                    )
                                                                    return
                                                                }

                                                                activateWithCode(
                                                                    activationCode,
                                                                    auth.uid,
                                                                    () => {
                                                                        setLoadingIndicator(false)
                                                                    }
                                                                )
                                                            }
                                                        )
                                                    }
                                                }}
                                            >
                                                {translate('payments.submit')} <TicketIcon />
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

const enhance = connect(({ firebase: { auth } }: RootState) => ({
    auth,
}))

// export const Home = withFirebase(HomePage);
export const Home = enhance(HomePage)
