import React from 'react'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { ProgressCircle } from '../../components/progress-circle/progress-circle'
import { User } from '../../components/user/user'
import config from '../../config/config'
import { COMPETITION } from '../../config/constants'
import { getPaymentResult, IPaymentResult } from '../../core/payment-result'
import { Language, translate } from '../../i18n'
import { selectCompetition } from '../../redux/app/reducer'
import { RootState } from '../../redux/store'
import './return.scss'
import { Card } from '../../components/card/card'
import { returnToApp } from '../../core/return-to-app'

const actionTryAgain = () => {
    window.location.replace('/')
}

const renderSucces = (paymentInfo: IPaymentResult, orderId: string | null) => (
    <div className="card full">
        <div className="result-info">
            <div className="image-container">
                <img src="/return-banner.png" />
            </div>

            <Card>
                <div className="title">{translate('return.success')}</div>
                <div className="description">{translate('return.setToWalk')}</div>
                <div className="descLong">{translate('return.orderFinishedDesc')}</div>
                <button onClick={returnToApp}>{translate('common.continue')}</button>
            </Card>
        </div>
    </div>
)

const renderFail = (paymentInfo: IPaymentResult, orderId: string | null) => (
    <div className="card full">
        <div className="result-info">
            <div className="image-container">
                <img src="/return-banner.png" />
            </div>
            <Card>
                <div className="title">{translate('return.error')}</div>
                <div className="description">{paymentInfo.description}</div>
                <div className="descLong">
                    {translate('return.transactionId')}
                    <br />
                    <strong>{orderId}</strong>
                    <div className="descLong">{translate('return.emailInfo')}</div>
                </div>
                <button onClick={actionTryAgain}>{translate('return.tryAgain')}</button>
            </Card>
        </div>
    </div>
)

export const Return = () => {
    const params = new URLSearchParams(window.location?.search)
    const orderId = params.get('orderId')
    let paymentInfo: IPaymentResult | undefined = undefined

    const competition = useSelector(selectCompetition)

    useFirestoreConnect([
        {
            collection: 'competitions',
            doc: COMPETITION,
            subcollections: [{ collection: 'payments', doc: orderId || '' }],
            storeAs: 'paymentInfo',
        },
    ])

    const payment = useSelector((state) => {
        // @ts-ignore
        return state.firestore.data.paymentInfo
    })

    if (payment) {
        paymentInfo = getPaymentResult(payment)
    }

    return (
        <div
            className={`return order-result ${
                paymentInfo && !paymentInfo.success && 'return-fail'
            }`}
        >
            {/*<div className="user-wrapper">*/}
            {/*    <div className="username">*/}
            {/*        <User />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {paymentInfo ? (
                paymentInfo.success ? (
                    renderSucces(paymentInfo, orderId)
                ) : (
                    renderFail(paymentInfo, orderId)
                )
            ) : (
                <div className="loading">{translate('return.loading')}</div>
            )}
        </div>
    )
}
