import { IAppState } from './state'
import { IAction } from '../types'
import { SELECT_LAGUAGE, SET_COMPETITION } from './actions'
import { RootState } from '../store'

const initialState: IAppState = {
    language: 'en',
    competition: '',
}

export default (state: IAppState = initialState, action: IAction) => {
    switch (action.type) {
        case SELECT_LAGUAGE:
            return {
                ...state,
                language: action.data.language,
            }
        case SET_COMPETITION:
            return {
                ...state,
                competition: action.data.competition,
            }

        default:
            break
    }
    return state
}

export const selectCompetition = (state: RootState) => state.app.competition
