import React from 'react'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { ProgressCircle } from '../../components/progress-circle/progress-circle'
import { User } from '../../components/user/user'
import config from '../../config/config'
import { getPaymentResult, IPaymentResult } from '../../core/payment-result'
import { Language, translate } from '../../i18n'
import { RootState } from '../../redux/store'
import './payment-done.scss'
import { returnToApp } from '../../core/return-to-app'
import { Card } from '../../components/card/card'

export const PaymentDone = () => {
    return (
        <div className="payment-done order-result">
            <div className="card full">
                <div className="result-info">
                    <div className="image-container">
                        <img src="/return-banner.png" />
                    </div>

                    <Card>
                        <div className="title">{translate('return.success')}</div>
                        <div className="description">{translate('payments.alreadyActivated')}</div>
                        <button onClick={returnToApp}>
                            {translate('return.activationComplete')}
                        </button>
                    </Card>
                </div>
            </div>
        </div>
    )
}
