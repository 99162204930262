import { ITranslations } from '../types'

export const ro: ITranslations = {
    texts: {
        common: {
            CHOOSE: 'ALEGE',
            BUY: 'Alege {{package}}',
            PACKAGE: 'PACHET',
            back: 'Inapoi',
            selectCounty: 'Alege judetul',
            name: 'Nume',
            address: 'Adresa',
            city: 'Oras',
            county: 'Judet',
            zip: 'Cod postal',
            phone: 'Telefon',
            continue: 'Continuă',
        },
        prices: {
            for: 'la',
        },
        navigation: {
            dashboard: 'Acasă',
            activationOptions: 'Opțiuni de activare',
            backToApp: 'Înapoi la aplicație',
        },
        packages: {
            donationInfo: '100% din taxele de înscriere sunt donate direct către ONG-ul partener',
            selected: 'Ai selectat',
            continueWith: "Continuă cu",
            selectionText:
                'Alege unul din cele trei pachete si ajuta copiii neurodivergenți"',
            packageDisplayName_basic: 'Pachetul Basic',
            packageDisplayName_premium: 'Pachetul Premium',
            packageDisplayName_vip: 'Pachetul VIP',
            onlyRomaniaTitle: 'Disponibil doar in Romania',
            onlyRomania: "Pachetele Premium și VIP pot fi livrate doar în România. Dacă locuiți într-o altă țară, vă rugăm să alegeți pachetul de donații Basic. 100% din taxele de înregistrare sunt direcționate către ONG-ul local.",
            packageDescription_basic:
                'Alege pachetul Basic dacă ai deja un fitness tracker sau o aplicație care numără pașii.',
            packageDescription_premium:
                'Pachetul Premium include atât activarea contului, cât și un fitness tracker Mi Band 8. Pachetul se ridică de la The Office sau cu livrare prin curier in Romania.',
            packageDescription_vip:
                'Fii printre susținătorii noștri de top cu Pachetul VIP! Ai șansa să ajuți mai mult și  primești cadou, pe deasupra, un ghiozdan reflectorizant personalizat Walking Month, cu livrare prin curier în România.',
        },
        payments: {
            activateYourAccount: 'Activeaza-ti contul',
            alreadyActivated: 'Contul tău a fost deja activat',
            cause: 'Alege pachetul preferat și ajută-ne să oferim 1.000.000 de minute de mișcare pentru copii.',
            canceled: 'Plată anulată',
            canceledDesc: 'Plata a fost anulată',
            confirmed: 'Plată confirmată',
            confirmedDesc: 'Plata a fost confirmată.',
            codeTitle: 'Invitatie',
            haveCode: 'Ai un code de activare?',
            companyCode: 'Cod de activare',
            instructions:
                'Apasă butonul de mai jos pentru a efectua plata, simplu și sigur, în câteva clipe.',
            instructionsWithPickup:
                'Selectează locul de unde vrei să ridici pachetul, iar apoi apasă butonul de mai jos pentru a efectua plata simplu și sigur.',
            notCompleted: 'Plată nefinalizată',
            notCompletedDesc: 'Plata nu s-a putut finaliza. Te rugăm să mai încerci o dată.',
            pending: 'Plată în așteptare',
            pendingDesc:
                'Plata este în  așteptare și va fi confirmată în cel mai scurt timp posibil.',
            periodOver: 'Contul nu poate fi activat în acest moment.',
            pickup: 'Alege dacă vrei să ridici pachetul VIP din Cluj sau scrie-ne detaliile pentru livrare gratuită prin curier oriunde în România.',
            refund: 'Plată înapoiată',
            refundDesc: 'Plata a fost înapoiată. Vei primi banii înapoi în curând.',
            rejected: 'Plată refuzată',
            rejectedDesc:
                'A apărut o problemă în timpul procesării plății. Te rugăm să reîncerci proocesul de plată.',
            useCode: 'Utilizează codul',
            submit: 'Trimite',
            activeYour: 'Activează-ți'
        },
        return: {
            activationComplete: 'Succes, ți-ai activat contul!',
            setToWalk: 'Ești gata pentru concurs!',
            success: 'Ai reuşit!',
            transactionId: 'ID-ul tranzacției este:',
            emailInfo: 'Aceste informații îți vor fi trimise și pe adresa de e-mail.',
            finlize: 'Alege pentru',
            tryAgain: 'Încearcă din nou',
            loading: 'se încarcă',
            orderFinishedDesc:
                'You can now join a team or even start your own team! And if you’re the lone wolf type, don’t worry, you can still take part in Walking Month even without a team.',
            finishActivation: 'Finalizează',
        },
        pickup: {
            delivery: 'Livrare prin curier în România',
            theOffice: 'Ridicare in Cluj-Napoca, la The Office, Bld. 21 Decembrie 1989, nr. 77, corp A, etajul 4, recepție, L-V, între orele 9:00 – 18:00. Vă rugăm să aveți pregătit un act de identitate.'
        }
    },
    plural: (n: number, ord?: boolean) => {
        const s = String(n).split('.')
        const v0 = !s[1]
        const t0 = Number(s[0]) === n
        const n10 = t0 && s[0].slice(-1)
        const n100 = t0 && s[0].slice(-2)

        if (ord) {
            return n10 === '1' && n100 !== '11'
                ? 'one'
                : n10 === '2' && n100 !== '12'
                ? 'two'
                : n10 === '3' && n100 !== '13'
                ? 'few'
                : 'other'
        }
        return n === 1 && v0 ? 'one' : 'other'
    },
}
