import React from 'react'

export const TicketIcon = (props: { className?: string }) => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Icon / Interface / Ticket">
                <path
                    id="Vector"
                    d="M9.83333 4H4.5C3.87875 4 3.56795 4 3.32292 4.10149C2.99621 4.23682 2.73682 4.49654 2.60149 4.82324C2.5 5.06827 2.5 5.3789 2.5 6.00015C3.60457 6.00015 4.5 6.89527 4.5 7.99987C4.5 9.1044 3.60457 10 2.5 10C2.5 10.6213 2.5 10.9319 2.60149 11.1769C2.73682 11.5036 2.99621 11.7631 3.32292 11.8985C3.56795 11.9999 3.87875 12 4.5 12H9.83333M9.83333 4H12.5C13.1213 4 13.4319 4 13.6769 4.10149C14.0036 4.23682 14.2631 4.49654 14.3985 4.82324C14.4999 5.06827 14.4999 5.3789 14.4999 6.00015C13.3953 6.00015 12.5 6.8954 12.5 8C12.5 9.1046 13.3953 10 14.4999 10C14.4999 10.6213 14.4999 10.9319 14.3985 11.1769C14.2631 11.5036 14.0036 11.7631 13.6769 11.8985C13.4319 11.9999 13.1213 12 12.5 12H9.83333M9.83333 4V12"
                    stroke="#FFB80C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}
