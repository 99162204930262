import React from 'react'
import './splash-screen.scss'

export const SplashScreen = () => {
    const params = new URLSearchParams(window.location?.search)
    const lang = params.get('lang')
    const text: any = {}

    return (
        <div className="splash-screen">
            <img src="/splash.png" />
        </div>
    )
}
