import React from 'react'
import './input.scss'

export const Input = ({
    label,
    value,
    onChangeText,
    validated,
    isValid,
    onBlur,
    readonly = false,
    onTap = null,
}: any) => {
    return (
        <div
            className={`input-component ${validated && isValid && 'valid'} ${
                validated && !isValid && 'error'
            }`}
        >
            <input
                type="text"
                value={value}
                onChange={(e) => onChangeText(e.target.value)}
                onBlur={() => {
                    onBlur()
                }}
                required
                readOnly={readonly}
                onClick={() => {
                    if (onTap) {
                        onTap()
                    }
                }}
            />
            <label>{label}</label>
        </div>
    )
}
