import { Language } from '../../i18n'

export const SELECT_LAGUAGE = 'SELECT_LAGUAGE'
export const SET_COMPETITION = 'SET_COMPETITION'

// this is not a selector, should have name "setLanguage"
export const selectLanguage = (language: Language) => {
    return {
        type: SELECT_LAGUAGE,
        data: {
            language
        }
    }
}

export const setCompetition = (competition: string) => {
    return {
        type: SET_COMPETITION,
        data: {
            competition
        }
    }
}
