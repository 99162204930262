import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
// import Firebase, { FirebaseContext } from './firebase';
import App from './App'

import { store } from './redux/store'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/remote-config'
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

import config from './config/config'
import { SplashScreen } from './components/splash-screen/splash-screen'
import { setCompetition } from './redux/app/actions'
import { selectCompetition } from './redux/app/reducer'
import { COMPETITION } from './config/constants'

// react-redux-firebase config
const rrfConfig = {
    userProfile: null,
    updateProfileOnLogin: false,
    autoPopulateProfile: false,
    enableLogging: false,
    logErrors: false,
}

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
}

// Initialize firebase instance
firebase.initializeApp(config.firebase)
firebase.firestore()
const remoteConfig = firebase.remoteConfig()

store.dispatch(setCompetition(COMPETITION))

// remoteConfig
//     .fetchAndActivate()
//     .then(() => {
//         store.dispatch(setCompetition(remoteConfig.getValue('competition').asString()))
//     })
//     .catch((err) => {
//         store.dispatch(setCompetition(COMPETITION))
//     })

const Preloader = () => {
    const competition = useSelector(selectCompetition)

    if (!competition) {
        return <SplashScreen />
    }

    // just to eliminate possibility of some errors for now
    return <App competition={competition} />
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <Preloader />
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
)
