import React from 'react'
import { ITranslationParams, translate } from './translate'

interface IProps {
    text: string;
    params?: ITranslationParams;
    count?: number;
}

export class Translate extends React.Component<IProps> {
    public render() {
        return (
            <div {...this.props}>
                {translate(this.props.text, this.props.params, this.props.count)}
            </div>
        )
    }
}

