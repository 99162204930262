import React from 'react'
import { translate } from '../../i18n'
import './package.scss'

export interface IProps {
    onClick: ((event: any) => void) | undefined
    price: number
    type: string
    currency: string
    unavailable?: boolean
    quantity: number
    withImage: boolean
    withTitle: boolean
}

export const Package = (props: IProps) => {
    const unavailable = props.unavailable || props.quantity < 1
    return (
        <div
            className={`card-slide card-slide--${props.type} ${unavailable && 'unavailable'}`}
            onClick={(e) => {
                if (!unavailable) {
                    if (typeof props.onClick === 'function') {
                        props.onClick(e)
                    }
                }
            }}
        >
            {props.withTitle && (
                <div className={'wm-title package-title'}>
                    {translate(`packages.packageDisplayName_${props.type}`)}
                </div>
            )}

            {props.withImage && (
                <div className="package-image">
                    <img src={`/${props.type}.png`} />
                </div>
            )}

            <div className="card-description">
                <p>{translate(`packages.packageDescription_${props.type}`)}</p>
            </div>

            <div className="card-action">
                <button className="glow">
                    {translate(`packages.packageDisplayName_${props.type}`)}
                    {` ${translate('prices.for')} `}
                    {`${props.price} `}
                    <span style={{ textTransform: 'uppercase' }}>{props.currency}</span>
                </button>
            </div>
        </div>
    )
}
