import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import appReducer from './app/reducer'

import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    app: appReducer,
})

const initialState = {}
const configureStore = () => {
    return createStore(rootReducer, initialState)
}

export const store = configureStore()

export type RootState = ReturnType<typeof rootReducer>